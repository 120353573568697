<template>
    <el-dialog :title="shiJiTitle" :visible.sync="editor.visible" @open="open"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="700px">
        <div class="formTitle">
            <div style="width: 50%;font-size: 30px;color: white">待付金额：{{yingFuKuanObj.daiFuJinE}}</div>
            <div style="width: 50%">
                <div class="rightTitle">
                    <span style="font-size: 20px">应付金额</span>
                    <span style="font-size: 20px">{{yingFuKuanObj.yingFuJinE}}</span>
                </div>
                <div class="rightTitle">
                    <span style="font-size: 20px">已付金额</span>
                    <span style="font-size: 20px">{{yingFuKuanObj.yiFuJinE}}</span>
                </div>
            </div>
        </div>
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="付款方式"  prop="fuKuanFS" class="line">
                <x-selector-one v-model="form.zhiFuFS" size="small" dictType="T_PAYMENT"/>
            </el-form-item>
            <el-form-item v-if="isYuFuKuanZf" label="预付余额"  prop="yuFuKuanYuE" class="line">
                <el-input v-model="form.yuFuKuanYuE" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="isYhkZf" label="收款方卡号"  prop="yinXingKaHao" class="line">
                <el-input v-model="form.yinXingKaHao" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item label="付款金额"  prop="jinE" class="line">
                <el-input v-model="form.jinE" size="small"></el-input>
            </el-form-item>
            <el-form-item label="付款时间" prop="fuKuanSJ" class="line">
                <el-date-picker v-model="form.fuKuanSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="备注"  prop="beiZhu" class="line">
                <el-input type="textarea" v-model="form.beiZhu" ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getUpdate} from "@/service/zjgl/CaiGouYingFuKuan";
    import {add} from "@/service/zjgl/CaiGouZiJinLiuShui";
    import {deepCopy} from "@/util/objects"
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_TYPE_PAYMENT} from "@/util/constant";

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required,positiveEightTwo} = ruleBuilder;
            this.rules = {
                jinE: [required('string', ['blur', 'change']),positiveEightTwo()], // 支付金额
                fuKuanSJ:[required('string', ['blur', 'change'])],
            }
            this.defaultForm = {
                id: null,
                keHuMC:'',//客户名称
                piCiHao:'',//批次号
                yinXingKaHao:'',//银行卡号
                yuFuKuanYuE:'',//预付余额
                kaiHuYinHang:'',//开户银行
                zhiFuFS: DICT_TYPE_PAYMENT.YFK.key,//支付方式
                jinE:'',//支付金额
                fuKuanSJ:'',//付款时间
                beiZhu:'',//备注
            };
            this.addService = add;
        },
        data() {
            return {
                shiJiTitle: "付款记录",
                yingFuKuanObj:{daiFuJinE:0,yingFuJinE:0,yiFuJinE:0}
            }
        },
        computed:{
            isYhkZf(){
              return this.form.zhiFuFS===DICT_TYPE_PAYMENT.YHK.key
            },
            isYuFuKuanZf(){
                return this.form.zhiFuFS === DICT_TYPE_PAYMENT.YFK.key
            }
        },
        methods: {
            open() {
                this.form=deepCopy(this.defaultForm)
                return getUpdate(this.editor.id).then((response) => {
                    this.yingFuKuanObj = response.data
                    this.form.keHuMC = this.yingFuKuanObj.gongHuoShang
                    this.form.piCiHao = this.yingFuKuanObj.piCiHao
                    this.form.yinXingKaHao = this.yingFuKuanObj.yinXingKaHao
                    this.form.yuFuKuanYuE = this.yingFuKuanObj.yuFuKuanYuE
                    this.form.kaiHuYinHang = this.yingFuKuanObj.kaiHuYinHang
                    //清空校验
                    this._clearValidate();
                    return response;
                })
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                //校验预付款余额
                if(DICT_TYPE_PAYMENT.YFK.key===this.form.zhiFuFS){
                    if(this.form.jinE>this.form.yuFuKuanYuE){
                        this.$message.warning("预付款余额不足！");
                        this.loading = false;
                        return ;
                    }
                }
                if(this.form.jinE>this.yingFuKuanObj.daiFuJinE){
                    this.$message.warning("支付金额不可大于待付金额！");
                    this.loading = false;
                    return ;
                }
                let api = this.addService;
                const response = await api(this.form).finally(() => {
                    this.loading = false;
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
        }
    }
</script>

<style scoped>
    .formTitle{
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: rgb(255, 117, 87);
        margin-bottom: 10px;
    }
    .rightTitle{
        display: flex;
        justify-content: space-between;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        color: white;
    }
</style>
