import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/cai-gou-zi-jin-liu-shui/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/cai-gou-zi-jin-liu-shui/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/cai-gou-zi-jin-liu-shui/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/cai-gou-zi-jin-liu-shui/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/cai-gou-zi-jin-liu-shui/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/cai-gou-zi-jin-liu-shui/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/cai-gou-zi-jin-liu-shui/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'cai-gou-zi-jin-liu-shui-list',
    component: () => import('@/view/zjgl/cgyfgl/CaiGouZiJinLiuShuiList'),
    name: 'CaiGouZiJinLiuShuiList',
    meta: {title: '采购资金流水', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"采购资金流水基础列表","methodUrl":"/cai-gou-zi-jin-liu-shui/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"采购资金流水添加保存","methodUrl":"/cai-gou-zi-jin-liu-shui/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"采购资金流水获取编辑数据","methodUrl":"/cai-gou-zi-jin-liu-shui/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"采购资金流水获取详情数据","methodUrl":"/cai-gou-zi-jin-liu-shui/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"采购资金流水编辑保存","methodUrl":"/cai-gou-zi-jin-liu-shui/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"采购资金流水删除","methodUrl":"/cai-gou-zi-jin-liu-shui/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"采购资金流水批量删除","methodUrl":"/cai-gou-zi-jin-liu-shui/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
